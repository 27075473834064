<template>
  <v-app>
    <div>
      <ModalIdle v-if="idle" v-on:onParent="logout" :dialog="idle" />
      <Navbar v-on:onParent="logout1" v-model="tab" />
      <v-container>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in tabs" :key="item">
            <component v-bind:is="item"></component>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import ModalIdle from "./ModalIdle";
import Navbar from "./Navbar";
import Codigos from "./Codigos";
import Usuarios from "./Usuarios";
import Maquinas from "./Maquinas";
import Clientes from "./Clientes";
import Historial from "./Historial";

export default {
  name: "Main",
  components: {
    ModalIdle,
    Navbar,
    Codigos,
    Usuarios,
    Maquinas,
    Clientes,
    Historial,
  },
  data: () => ({
    tab: null,
    tabs: ["Codigos", "Usuarios", "Maquinas", "Historial"], //"Clientes",
    idle: false
  }),
  methods: {
    logout: function () {
      this.$session.destroy();
      this.inactive();
    },
     logout1: function () {
      this.$session.destroy();
      this.$emit("onParent");
      this.inactive();
    },
    inactive: function(){
      this.$api.get("/login/inactive");
      delete this.$api.defaults.headers.common["Authorization"];
    },
  },
  onIdle() {
    this.idle = true;
  },
  onActive() {
    if (this.$session.exists()) {
      this.idle = false;    
    }
    else{
      this.$emit("onParent");
    }
  }
};
</script>
