var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"sort-by":"calories"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Nuevo ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","-show":"editedIndex === -1"}},[_c('v-text-field',{attrs:{"label":"Serial","required":""},model:{value:(_vm.editedItem.Serial),callback:function ($$v) {_vm.$set(_vm.editedItem, "Serial", $$v)},expression:"editedItem.Serial"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-switch',{attrs:{"label":"Full Licencia"},model:{value:(_vm.editedItem.FullLicence),callback:function ($$v) {_vm.$set(_vm.editedItem, "FullLicence", $$v)},expression:"editedItem.FullLicence"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Max Fecha Licencia","prepend-icon":"mdi-calendar","readonly":"","required":""},model:{value:(_vm.editedItem.MaxFecha),callback:function ($$v) {_vm.$set(_vm.editedItem, "MaxFecha", $$v)},expression:"editedItem.MaxFecha"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{model:{value:(_vm.editedItem.MaxFecha),callback:function ($$v) {_vm.$set(_vm.editedItem, "MaxFecha", $$v)},expression:"editedItem.MaxFecha"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_vm._v(" Cliente ")])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("¿Esta seguro que desea eliminar el registro?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDelete}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Confirmar")]),_c('v-spacer')],1)],1)],1)],1),_c('v-alert',{attrs:{"transition":"scale-transition","dismissible":"","type":"error"},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.alertmessage)+" ")])]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" No hay resultados ")])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }