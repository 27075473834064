<template>
  <v-app>
    <v-main>
      <div>
        <v-container class="mb-6">
          <AlertError :alerterror="alert" :alerterrormessage="alertmessage"/>
          <v-form v-model="valid" ref="form" lazy-validation>
            <v-row align="center" justify="center" style="margin-top: 20%">
              <v-col xl="3" md="3" sm="8">
                <v-text-field
                  v-model="user"
                  :rules="userRules"
                  label="Usuario"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col xl="3" md="3" sm="8">
                <v-text-field
                  v-model="pass"
                  :rules="passRules"
                  type="password"
                  label="Contraseña"
                  required
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col xl="3" md="3" sm="8">
                <v-btn color="success" block large outlined @click="validate">
                  Ingresar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    valid: true,
    alert: false,
    alertMessagge: "",
    user: "",
    pass: "",
    userRules: [(v) => !!v || "Usuario requerido"],
    passRules: [(v) => !!v || "Contraseña requerida"],
  }),
  methods: {
    validate: function () {
      this.$refs.form.validate();
      delete this.$api.defaults.headers.common["Authorization"];
      var reg = localStorage.getItem("uid") === null ? "" :localStorage.getItem("uid");
      this.$api
        .post("/login/authenticate", {
          Username: this.user,
          Password: this.pass,
          Reg: reg,
          Type: this.$device.machine.type.toLocaleUpperCase()
        })
        .then((response) => {
          if (response.data.token !== undefined) {
            this.$api.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.token;
            this.$session.start();
            this.$session.set("jwt", response.data.token);
            this.$api
              .get("/user/get")
              .then((response) => {
                // console.log(response.data);
                this.$session.set("nombre", response.data.Nombre);
                this.$session.set("maxfecha", response.data.MaxFechaLicencia);
                this.$session.set("admin", response.data.Admin);
                this.$session.set("fulllicence", response.data.FullLicence);
                this.$emit("onParent", true);
                if(this.$device.machine.type.toLocaleUpperCase() == 'DESKTOP')
                  if(localStorage.getItem("uid") !== null && localStorage.getItem("uid") !== response.data.RegDesktop)
                    localStorage.setItem("uid",response.data.RegDesktop);
                else if(localStorage.getItem("uid") !== null && this.$device.machine.type.toLocaleUpperCase() != 'DESKTOP')
                  if(localStorage.getItem("uid") !== response.data.RegMobile)
                    localStorage.setItem("uid",response.data.RegMobile);

              })
              .catch((error) => {
                console.log(error.response);
              });
          }else{
            this.alert = true;
            this.alertMessagge = response.data.msg;
            setTimeout(() => (this.logout()), 1000);
          }
        })
        .catch((error) => {
          this.alert = true;
          this.alertMessagge =
            "Hubo un error en la comunicacion, por favor intentelo mas tarde";
          console.log(error);
          setTimeout(() => (this.logout()), 1000);
        });
    },
    logout: function(){
      this.$session.destroy();
      this.$emit("onParent", false);
    }
  },
};
</script>
