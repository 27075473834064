<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-textarea
          outlined
          name="text"
          label="Codigos"
          value=""
          v-model="text"
          ref="textarea"
          :rules="textRules"
          required
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
        <v-col cols="4" md="2" offset-md="8">
        <v-btn
          class="ma-2"
         color="info"
          @click="limpiar"
        >
          Limpiar
        </v-btn>
      </v-col>
      <v-col cols="6" md="2">
        <v-btn
          class="ma-2"
          :loading="loading"
          :disabled="loading"
          color="info"
          @click="cargar"
          ref="button"
        >
          Cargar codigos
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-col>
    </v-row>
    <List v-bind:listas="listas" v-show="listas != null"></List>
  </div>
</template>


<script>
import List from "./List";

export default {
  name: "Codigos",
  components: {
    List,
  },
  data: () => ({
    loader: null,
    loading: false,
    listas: null,
    text: null,
    textRules: [(v) => !!v || "Codigos requeridos"],
  }),
  mounted() {
    this.listas = null;
  },
  methods: {
    cargar: function () {
      this.$refs.textarea.validate();
      this.loader = "loading";
      if (this.text != "") {
        this.$api
          .post("/code/decrypt", { text: this.text })
          .then((response) => {
            if (
              response.data[0].length > 0 ||
              response.data[1].length > 0 ||
              response.data[2].length > 0
            ) {
              this.listas = response.data;
              console.log(this.listas);
              this.text = "";
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
      else{
          this.listas = null;
      }
    },
    limpiar: function(){
         this.text = "";
         this.listas = null;
    }
  },
  computed: {},
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 1000);
      this.loader = null;
    },
  },
};
</script>
