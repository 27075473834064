<template>
  <v-dialog persistent v-model="dialog" max-width="290">
    <v-card>
      <v-card-title class="text-h5"> Sesión Inactiva </v-card-title>
      <v-card-text>
        Se ha detectado inactividad, la sesion se cerrara en {{ second }} segundos
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalIdle",
  props: ["dialog"],
  data: function () {
    return {
      time: 100000,
    };
  },
  computed: {
    second() {
      return this.time / 10000;
    },
  },
  created() {
    let timerId = setInterval(() => {
      this.time -= 10000;
      if (!this.dialog) clearInterval(timerId);
      if (this.time < 1) {
        clearInterval(timerId);
        this.$emit("onParent");
      }
    }, 1000);
  }
};
</script>