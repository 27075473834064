<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Nuevo
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.Nombre"
                        label="Nombre"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" v-show="editedIndex === -1">
                      <v-text-field
                        v-model="editedItem.Documento"
                        label="Documento"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="editedItem.Password"
                        label="Contraseña"
                        type="password"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-switch
                        v-model="editedItem.Admin"
                        label="Admin"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-switch
                        v-model="editedItem.FullLicence"
                        label="Full Licencia"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.MaxFechaLicencia"
                            label="Fecha Licencia"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.MaxFechaLicencia"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-switch
                        v-model="editedItem.Activo"
                        label="Activo"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" v-show="editedItem.Activo">
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.MaxFechaActivo"
                            label="Fecha Activo"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.MaxFechaActivo"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-switch
                        v-model="editedItem.Gambee"
                        label="Gambee"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-switch
                        v-model="editedItem.SlotGC"
                        label="SlotGC"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-switch
                        v-model="editedItem.RuletaGC"
                        label="RuletaGC"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="8">
                      <v-slider
                        v-model="editedItem.Dispositivos"
                        class="align-center"
                        max="10"
                        min="1"
                        hide-details
                        label="Dipositivos"
                      >
                        <template v-slot:append>
                          <v-text-field
                            v-model="editedItem.Dispositivos"
                            class="mt-0 pt-0"
                            hide-details
                            single-line
                            type="number"
                            style="width: 40px"
                          ></v-text-field>
                        </template>
                      </v-slider>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Guardar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <DialogDelete :dialogDelete="dialogDelete" :closeDelete="closeDelete" :deleteItemConfirm="deleteItemConfirm"/>
      </v-toolbar>
     <AlertError :alerterror="alert" :alerterrormessage="alertmessage"/>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="mr-2" color="blue darken-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
        </template>
        <span>Editar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="mr-2" color="red darken-2" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
        </template>
        <span>Borrar</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="mr-2" color="green darken-2" @click="deleteItem(item)">
        mdi-lock-open
      </v-icon>
        </template>
        <span>Desbloquear</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-icon  v-bind="attrs" v-on="on" class="mr-2" @click="deleteItem(item)">
        mdi-desktop-classic
      </v-icon>
        </template>
        <span>Dispositivos</span>
      </v-tooltip>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> No hay resultados </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import AlertError from "./AlertError";
import DialogDelete from "./DialogDelete";
export default {
  components: {
    AlertError,
    DialogDelete
  },
  data: () => ({
    slider: 1,
    valid: true,
    alert: false,
    alertmessage: "",
    search: "",
    menu: false,
    menu1: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Nombre", align: "start", value: "Nombre" },
      { text: "Documento", value: "Documento" },
      { text: "Admin", value: "Admin" },
      { text: "Full Licencia", value: "FullLicence" },
      { text: "Max Fecha Licencia", value: "MaxFechaLicencia" },
      { text: "Activo", value: "Activo" },
      { text: "Max Fecha Activo", value: "MaxFechaActivo" },
      { text: "Ultimo login", value: "UltimaFechaLogin" },
      { text: "Gambee", value: "Gambee" },
      { text: "SlotGC", value: "SlotGC" },
      { text: "RuletaGC", value: "RuletaGC" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      Nombre: "",
      Documento: 0,
      Admin: false,
      FullLicence: false,
      MaxFechaLicencia: "",
      Activo: true,
      Password: "",
      Gambee: true,
      RuletaGC: true,
      SlotGC: true,
      Dispositivos: 1,
    },
    defaultItem: {
      Nombre: "",
      Documento: 0,
      Admin: false,
      FullLicence: false,
      MaxFechaLicencia: "",
      Activo: true,
      Password: "",
      Gambee: true,
      RuletaGC: true,
      SlotGC: true,
      Dispositivos: 1,
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo" : "Editar";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$api
        .get("/user/all")
        .then((response) => {
          this.desserts = response.data;
          this.desserts.forEach((element) => {
            element.MaxFechaLicencia = this.$options.filters.formatDate(
              element.MaxFechaLicencia
            );
            element.MaxFechaActivo = this.$options.filters.formatDate(
              element.MaxFechaActivo
            );
            element.UltimaFechaLogin = this.$options.filters.formatDate(
              element.UltimaFechaLogin
            );
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$api
        .post("/user/delete", this.editedItem)
        .then((response) => {
          console.log(response);
          this.desserts.splice(this.editedIndex, 1);
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.alertmessage = "";
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$refs.form.validate();
      if (this.editedIndex > -1) {
        this.$api
          .post("/user/update", this.editedItem)
          .then((response) => {
            if (response.data.valid == true) {
              response.data.user.MaxFechaLicencia =
                this.$options.filters.formatDate(
                  response.data.user.MaxFechaLicencia
                );
              response.data.user.MaxFechaActivo =
                this.$options.filters.formatDate(
                  response.data.user.MaxFechaActivo
                );
              response.data.user.UltimaFechaLogin =
                this.$options.filters.formatDate(
                  response.data.user.UltimaFechaLogin
                );
              Object.assign(
                this.desserts[this.editedIndex],
                response.data.user
              );
            } else {
              this.alert = true;
              this.alertmessage = "Hubo un error al actualizar el usuario";
            }
            this.close();
          })
          .catch((error) => {
            this.alert = true;
            this.alertmessage = "Hubo un error al actualizar el usuario";
            console.log(error.response);
            this.close();
          });
      } else {
        this.$api
          .post("/user/create", this.editedItem)
          .then((response) => {
            if (response.data.valid == true) {
              response.data.user.MaxFechaLicencia =
                this.$options.filters.formatDate(
                  response.data.user.MaxFechaLicencia
                );
              response.data.user.MaxFechaActivo =
                this.$options.filters.formatDate(
                  response.data.user.MaxFechaActivo
                );
              response.data.user.UltimaFechaLogin =
                this.$options.filters.formatDate(
                  response.data.user.UltimaFechaLogin
                );
              this.desserts.push(response.data.user);
            } else {
              this.alert = true;
              this.alertmessage = "Hubo un error al crear el usuario";
            }
            this.close();
          })
          .catch((error) => {
            console.log(error.response);
            this.alert = true;
            this.alertmessage = "Hubo un error al crear el usuario.";
            this.close();
          });
      }
    },
  },
};
</script>