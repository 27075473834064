<template>
  <v-data-table
    :headers="headers"
    :items="desserts"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              Nuevo
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-row>
                    <v-col cols="12" sm="6" md="4" -show="editedIndex === -1">
                      <v-text-field
                        v-model="editedItem.Serial"
                        label="Serial"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-switch
                        v-model="editedItem.FullLicence"
                        label="Full Licencia"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu
                        v-model="menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="editedItem.MaxFecha"
                            label="Max Fecha Licencia"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            required
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editedItem.MaxFecha"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        Cliente
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Guardar </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >¿Esta seguro que desea eliminar el registro?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancelar</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >Confirmar</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
      <v-alert
        v-model="alert"
        transition="scale-transition"
        dismissible
        type="error"
      >
        {{ alertmessage }}
      </v-alert>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> No hay resultados </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data: () => ({
    valid: true,
    alert: false,
    alertmessage: "",
    search: "",
    menu: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: "Nit", align: "start", value: "Nit" },
      { text: "Nombre", value: "Nombre" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      Nit: "",
      MaxFecha: "",
      Cliemte: ""
    },
    defaultItem: {
     Serial: "",
      FullLicence: false,
      MaxFecha: "",
      Cliemte: ""
    },
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo" : "Editar";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$api
        .get("/client/all")
        .then((response) => {
          this.desserts = response.data;
          this.desserts.forEach((element) => {
            element.MaxFecha = this.$options.filters.formatDate(
              element.MaxFecha
            );
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$api
        .post("/client/delete", this.editedItem)
        .then((response) => {
          console.log(response);
          this.desserts.splice(this.editedIndex, 1);
        })
        .catch((error) => {
          console.log(error.response);
        });
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.alertmessage = "";
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      this.$refs.form.validate();
      if (this.editedIndex > -1) {
        this.$api
          .post("/client/update", this.editedItem)
          .then((response) => {
            if (response.data.valid == true) {
              response.data.user.MaxFecha =
                this.$options.filters.formatDate(
                  response.data.user.MaxFecha
                );
              Object.assign(
                this.desserts[this.editedIndex],
                response.data.user
              );
            } else {
              this.alert = true;
              this.alertmessage = "Hubo un error al actualizar el cliente";
            }
            this.close();
          })
          .catch((error) => {
            this.alert = true;
            this.alertmessage = "Hubo un error al actualizar el cliente";
            console.log(error.response);
            this.close();
          });
      } else {
        this.$api
          .post("/client/create", this.editedItem)
          .then((response) => {
            if (response.data.valid == true) {
              response.data.user.MaxFecha =
                this.$options.filters.formatDate(
                  response.data.user.MaxFecha
                );
              this.desserts.push(response.data.user);
            } else {
              this.alert = true;
              this.alertmessage = "Hubo un error al crear el cliente";
            }
            this.close();
          })
          .catch((error) => {
            console.log(error.response);
            this.alert = true;
            this.alertmessage = "Hubo un error al crear el cliente.";
            this.close();
          });
      }
      
    },
  },
};
</script>