<template>
     <v-app-bar color="white" dense>
        <v-toolbar-title>Trials</v-toolbar-title>
        <v-spacer></v-spacer>
        {{ username }}
        <v-btn icon @click="logout">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
         <template v-slot:extension>
            <v-tabs align-with-title v-show="admin" v-model="tab">
              <v-tabs-slider color="black"></v-tabs-slider>
              <v-tab v-for="item in tabs" :key="item">{{ item }}</v-tab>
            </v-tabs>
          </template>
      </v-app-bar>
</template>

<script>
export default {
  name: "Navbar",
  data: () => ({
    tab: null,
    tabs: ["Codigos","Usuarios","Maquinas","Historial"],//"Clientes",
  }),
  methods: {
    logout: function () {
      this.$emit("onParent");
    },
  },
  computed: {
    username: function () {
      return this.$session.get("nombre");
    },
    admin: function(){
      return this.$session.get("admin");
    }
  },
  watch: {
    tab() {
      this.$emit("input",this.tab);
    },
  },
};
</script>

