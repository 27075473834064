<template>
  <v-row>
    <v-col cols="12" md="12">
      <v-divider></v-divider>
      <v-list>
        <v-list-group
          v-for="lista in listas1"
          :key="lista.title"
          v-model="lista.active"
          no-action
          v-show="lista.items.length > 0"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                v-text="`${lista.items.length} ${lista.title}`"
              ></v-list-item-title>
            </v-list-item-content>
          </template>
          <v-divider></v-divider>Opciones para la lista<v-divider></v-divider>
          <v-row style="background: #f2f2f2">
            <v-col cols="12" md="4">
              <v-menu
                v-model="lista.menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="lista.fecha"
                    label="Fecha para la lista"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="lista.fecha"
                  :max="maxfecha"
                  @input="datelist(lista)"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="1">
              <v-switch
                v-if="lista.bydate != null"
                v-model="lista.bydate"
                label="By Date"
                @change="checkb(lista)"
                :disabled="!fulllic"
              ></v-switch>
            </v-col>
            <v-col cols="6" md="1">
              <v-switch
                v-if="lista.fulllic != null && fulllic"
                v-model="lista.fulllic"
                label="Full Licence"
                @change="checkf(lista)"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row
            v-for="child in lista.items"
            :key="child.title"
          >
            
            <v-col cols="12" md="6" class="d-none d-sm-block">
              <v-list-item-title v-text="child.title"></v-list-item-title>
              <v-list-item-subtitle
                class="text--primary"
                v-text="child.subtitle"
              ></v-list-item-subtitle>
              <v-list-item-subtitle
                v-text="child.newcode"
              ></v-list-item-subtitle>
            </v-col>
            <div class="d-block d-sm-none"><v-divider></v-divider>&nbsp;&nbsp;&nbsp;{{child.machine}}<v-divider></v-divider></div>
             <v-col cols="12" md="6" class="d-block d-sm-none">
              <v-list-item-title>Code</v-list-item-title>
              <v-list-item-subtitle
                class="text--primary"
                v-text="child.code"
              ></v-list-item-subtitle>
            </v-col>
             <v-col cols="12" md="6" class="d-block d-sm-none">
               <v-list-item-title>New Code</v-list-item-title>
               <v-list-item-subtitle
                class="text--primary"
                v-text="child.newcode"
              ></v-list-item-subtitle>
            </v-col>
             <div class="d-block d-sm-none"><v-divider></v-divider>&nbsp;&nbsp;&nbsp;Opciones para {{child.machine}}<v-divider></v-divider></div>
            <v-col cols="12" md="2">
              <v-menu
                v-model="child.menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="child.fecha"
                    label="Fecha"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="child.fecha"
                  :max="child.maxfecha"
                  @input="child.menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="5" md="1">
              <v-switch
                v-if="child.bydate != null"
                v-model="child.bydate"
                label="By Date"
                @change="check1b(child)"
                :disabled="!fulllic"
              ></v-switch>
            </v-col>
            <v-col cols="5" md="1">
              <v-switch
                v-if="child.fulllic != null && fulllic"
                v-model="child.fulllic"
                label="Full Licence"
                @change="check1f(child)"
              ></v-switch>
            </v-col>
            <v-col cols="1" md="1">
              <v-btn
                style="margin: 40%"
                icon
                color="indigo"
                v-if="child.newcode"
                v-clipboard="
                  `Machine: ${child.machine}\nNewCode: ${child.newcode}`
                "
              >
                <v-icon>mdi-content-paste</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-list-group>
      </v-list>
    </v-col>
     <v-alert
        v-model="alert"
        transition="scale-transition"
        dismissible
        type="success"
      >
        Los nuevos codigos estan siendo copiados al portapapeles
      </v-alert>
    <v-col md="2" offset-md="10">
      <v-btn
        class="ma-2"
        :loading="loading"
        :disabled="loading"
        color="info"
        id="butbot"
        @click="obtener"
      >
        Obtener codigos
        <template v-slot:loader>
          <span class="custom-loader">
            <v-icon light>mdi-cached</v-icon>
          </span>
        </template>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "List",
  props: {
    listas: {
      required: true,
    },
  },
  data: () => ({
    alert: false,
    loader: null,
    loading: false,
    listas1: [
      {
        menu: false,
        fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        active: false,
        items: [],
        title: "Slot GC",
        bydate: true,
        fulllic: false,
      },
      {
        menu: false,
        fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        active: false,
        items: [],
        title: "Ruleta GC",
        bydate: true,
        fulllic: false,
      },
      {
        menu: false,
        fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        active: false,
        items: [],
        title: "Gambee",
      },
    ],
  }),
  methods: {
     obtener: async function () {
      this.loader = "loading";
      var i,
        copy = "";
      for (i = 0; i < this.listas1[0].items.length; i++) {
        var code = this.listas1[0].items[i];
        var newcode = await this.$api
          .post("/code/crypt", {
            text: code.text,
            fecha: code.fecha,
            bydate: code.bydate,
            full: code.fulllic,
          })
          .then((response) => {
            return response.data[0][0].activationCode;
          })
          .catch((error) => {
            console.log(error.response);
            return "";
          });
        this.listas1[0].items[i].newcode = newcode
        copy += `Machine: ${code.machine}\nNewCode: ${newcode}\n`;
      }
      for (i = 0; i < this.listas1[1].items.length; i++) {
        var code1 = this.listas1[1].items[i];
        var newcode1 = await this.$api
          .post("/code/crypt", {
            text: code1.text,
            fecha: code1.fecha,
            bydate: code1.bydate,
            full: code1.fulllic,
          })
          .then((response) => {
            return response.data[1][0].activationCode;
          })
          .catch((error) => {
            console.log(error);
             return "";
          });
        this.listas1[1].items[i].newcode = newcode1;
        copy += `Machine: ${code1.machine}\nNewCode: ${newcode1}\n`;
      }
      for (i = 0; i < this.listas1[2].items.length; i++) {
        var code2 = this.listas1[2].items[i];
        var newcode2 = await this.$api
          .post("/code/crypt", {
            text: code2.text,
            fecha: code2.fecha,
            bydate: code2.bydate,
            full: code2.fulllic,
          })
          .then((response) => {
            return response.data[2][0].activationCode;
          })
          .catch((error) => {
            console.log(error.response);
            return "";
          });
        this.listas1[2].items[i].newcode = newcode2;
        copy += `Machine: ${code2.machine}\nNewCode: ${newcode2}\n`;
      }
      this.copytext(copy)
    },
    copytext(text){
       document.getElementById('butbot').scrollIntoView({
        behavior: "smooth"
      });
      setTimeout(() => { this.alert = true}, 3000);
      this.$clipboard(text);
    },
    checkb: function (l) {
      l.fulllic = !l.bydate;
      for (var i = 0; i < l.items.length; i++) {
        l.items[i].bydate = l.bydate;
        l.items[i].fulllic = l.fulllic;
      }
    },
    checkf: function (l) {
      l.bydate = !l.fulllic;
      for (var i = 0; i < l.items.length; i++) {
        l.items[i].bydate = l.bydate;
        l.items[i].fulllic = l.fulllic;
      }
    },
    check1b: function (c) {
      c.fulllic = !c.bydate;
    },
    check1f: function (c) {
      c.bydate = !c.fulllic;
    },
    datelist: function (l) {
      l.menu = false;
      for (var i = 0; i < l.items.length; i++) {
        if (l.items[i].maxfecha >= l.fecha) l.items[i].fecha = l.fecha;
        else l.items[i].fecha = l.items[i].maxfecha;
      }
    },
  },
  computed: {
    maxfecha: function () {
      return this.$session.get("maxfecha");
    },
    fulllic: function () {
      return this.$session.get("fulllicence");
    },
  },
  watch: {
    loader() {
      const l = this.loader;
      this[l] = !this[l];
      setTimeout(() => (this[l] = false), 1000);
      this.loader = null;
    },
    alert() {
      setTimeout(() => (this.alert = false), 3000);
    },
    listas: function () {
      if (this.listas != null) {
        var i, tmp;
        this.listas1[0].items = [];
        for (i = 0; i < this.listas[0].length; i++) {
          tmp = {
            title: `Machine: ${this.listas[0][i].Machine} Code: ${this.listas[0][i].Code}`,
            subtitle: `TrialType: ${this.listas[0][i].TrialType} Version: ${this.listas[0][i].Ver} TrialDate: ${this.listas[0][i].TrialDate}`,
            text: `${this.listas[0][i].Code}\n${this.listas[0][i].Machine} / ${this.listas[0][i].Ver}`,
            newcode: "",
            maxfecha:
              this.listas[0][i].fechaMax != "0001-01-01T00:00:00"
                ? this.$options.filters.formatDate(this.listas[0][i].fechaMax)
                : this.maxfecha,
            menu: false,
            fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
            bydate: true,
            fulllic: false,
            machine: this.listas[0][i].Machine,
            code: this.listas[0][i].Code,
          };

          this.listas1[0].items.push(tmp);
        }
        this.listas1[1].items = [];
        for (i = 0; i < this.listas[1].length; i++) {
          tmp = {
            title: `Machine: ${this.listas[1][i].Machine} Code: ${this.listas[1][i].Code} `,
            subtitle: `TrialType: ${this.listas[1][i].TrialType} Version: ${this.listas[1][i].Version} TrialDate: ${this.listas[1][i].TrialDate}`,
            text: `${this.listas[1][i].Machine} ${this.listas[1][i].Code}`,
            newcode: "",
            maxfecha:
              this.listas[1][i].fechaMax != "0001-01-01T00:00:00"
                ? this.$options.filters.formatDate(this.listas[1][i].fechaMax)
                : this.maxfecha,
            menu: false,
            fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
            bydate: true,
            fulllic: false,
            machine: this.listas[1][i].Machine,
            code: this.listas[1][i].Code,
          };
          this.listas1[1].items.push(tmp);
        }
        this.listas1[2].items = [];
        for (i = 0; i < this.listas[2].length; i++) {
          tmp = {
            title: `Machine: ${this.listas[2][i].Serial}`,
            subtitle: `HardwareId: ${this.listas[2][i].HardwareId}`,
            text: `${this.listas[2][i].Serial} ${this.listas[2][i].HardwareId}`,
            newcode: "",
            maxfecha:
              this.listas[2][i].fechaMax != "0001-01-01T00:00:00"
                ? this.$options.filters.formatDate(this.listas[2][i].fechaMax)
                : this.maxfecha,
            menu: false,
            fecha: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10),
            machine: this.listas[2][i].Serial,
            hardwareId: this.listas[2][i].HardwareId,
          };
          this.listas1[2].items.push(tmp);
        }
      }
    },
  },
};
</script>