<template>
  <v-alert
    v-model="alerterror"
    transition="scale-transition"
    dismissible
    type="error"
  >
    {{ alerterrormessage }}
  </v-alert>
</template>

<script>
export default {
  name: "AlertError",
  props: ["alerterror","alerterrormessage"],
};
</script>