import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import api from './plugins/axios';
import VueSession from 'vue-session'
import Clipboard from 'v-clipboard'
import IdleVue from "idle-vue";
import device from 'vue-device-detector-js'

import "@/assets/global.css"

const eventsHub = new Vue();
const idleTimer = 300000; //300000

Vue.use(VueSession);
Vue.use(Clipboard);
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: idleTimer
});
Vue.use(device)

Vue.config.productionTip = false;
Vue.prototype.$api = api;

let app = new Vue({
  vuetify,
  render: h => h(App),
  methods: {
    off: function(){
      if (this.$session.exists()) {
        this.$api.get("/login/inactive");
        delete this.$api.defaults.headers.common["Authorization"];
      }
    },
  },
}).$mount('#app')

Vue.filter('formatDate', function (value) {
  if (value) {
    var d = new Date(value),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
})

document.onkeydown = fkey;
document.onkeyup = fkey;

var wasPressed = true;

function fkey(e){
  e = e || window.event;
  wasPressed = !(e.keyCode == 116);
}

window.onbeforeunload = function() {
  if(wasPressed)
    app.off();
};
