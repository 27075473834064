<template>
 <component v-on:onParent="infoChild" v-bind:is="currentTabComponent">
  </component>
</template>

<script>
import Login from "./components/Login";
import Main from "./components/Main";

export default {
  name: 'App',
  components: {
    Login,
    Main
  },
  data: () => ({
    currentTabComponent: Login
  }),
  beforeMount: function () {
    if (this.$session.exists()) {
      this.$api.defaults.headers.common["Authorization"] = "Bearer " + this.$session.get("jwt");
      this.infoChild(true);
    }
    else
      this.infoChild(false);
  },
  methods: {
    infoChild: function (arg) {
      this.currentTabComponent = arg ? Main : Login;
    },
  },
};
</script>
