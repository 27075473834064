<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
      :custom-filter="filterOnlyCapsText"
      :expanded.sync="expanded"
      item-key="NewCode"
      show-group-by
      show-expand
      class="elevation-1"
    >
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <div id="slot" v-show="item.Tipo.Id == 1">
            <b>Code:</b>{{ item.Code }} <b>TrialType:</b>{{ item.TrialType }}
            <b>TrialVer:</b>{{ item.TrialVer }} <b>TrialDate:</b
            >{{ item.TrialDate }} <b>FullLicence:</b>{{ item.FullLicence }}
          </div>
          <div id="ruleta" v-show="item.Tipo.Id == 2">
            <b>Code:</b>{{ item.Code }} <b>TrialType:</b>{{ item.TrialType }}
            <b>TrialVer:</b>{{ item.TrialVer }} <b>TrialDate:</b
            >{{ item.TrialDate }} <b>FullLicence:</b>{{ item.FullLicence }}
          </div>
          <div id="gambee" v-show="item.Tipo.Id == 3">
            <b>HardwareId:</b>{{ item.HardwareId }}
          </div>
        </td>
      </template>
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Buscar"
          class="mx-4"
          append-icon="mdi-magnify"
          single-line
          hide-details
        ></v-text-field>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize"> No hay resultados </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      desserts: [],
      expanded: [],
    };
  },
  computed: {
    headers() {
      return [
        { text: "Maquina ", value: "Maquina" },
        { text: "Tipo ", value: "Tipo.Nombre" },
        { text: "Fecha ", value: "Fecha" },
        { text: "Responsable ", value: "Responsable.Nombre" },
        { text: "Hasta ", value: "FechaHasta" },
        { text: "Codigo ", value: "NewCode" },
        { text: "+ ", value: "data-table-expand" },
      ];
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$api
        .get("/record/all")
        .then((response) => {
          this.desserts = response.data;
          this.desserts.forEach((element) => {
            element.MaxFecha = this.$options.filters.formatDate(
              element.MaxFecha
            );
          });
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleUpperCase().indexOf(search.toString().toLocaleUpperCase()) !== -1
      );
    },
  },
};
</script>